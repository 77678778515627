





































































































































































































































//@import '@core/scss/vue/libs/vue-select.scss';

.item-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.8rem;
  padding: 0 0.8rem 0.8rem 0;

  > div {
    flex: 0 0 50%;
    box-sizing: border-box;
    padding: 0.8rem 0 0 0.8rem;

    > div {
      position: relative;
      padding: 0.5rem;
    }
  }

  .box-close {
    top: -9px;
    right: -9px;
    left: auto;
    bottom: auto;
  }
}
